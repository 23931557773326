export enum AppOption {
  DUMMY_OPTION = 'DUMMY_OPTION',
}

const DEFAULT_OPTIONS: Record<AppOption, string> = {
  [AppOption.DUMMY_OPTION]: 'false',
};

function getOptionValue(key: AppOption): string | null {
  return localStorage.getItem(`wlom:app:${key}`);
}

function setOptionValue(key: AppOption, value: string) {
  localStorage.setItem(`wlom:app:${key}`, value);
}

function isOptionEnabled(key: AppOption): boolean {
  return getOptionValue(key) === 'true';
}

export function useAppSettings() {
  const options: Record<AppOption, string> = Object.fromEntries(
    Object.keys(AppOption).map((key) => [key, getOptionValue(key as AppOption) ?? DEFAULT_OPTIONS[key as AppOption]])
  ) as Record<AppOption, string>;

  function setOption(key: AppOption, value: string) {
    setOptionValue(key, value);
    options[key] = value;
    window.history.go(0);
  }

  return {
    options,
    setOption,
    isOptionEnabled,
  };
}
