import { css } from '@emotion/react';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from '@ionic/react';
import React from 'react';
import OopsImg from '../../assets/img/oops.svg?react';
import ShrugImg from '../../assets/img/shrug.svg?react';
import * as commonCss from '../../styles/common';
import { Title } from '../Title';

const OopsManCss = {
  card: css({
    margin: 'auto',
    maxWidth: '500px',
  }),
  centeredText: css({
    textAlign: 'center',
  }),
  attribution: css({
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    bottom: 0,
  }),
};

interface OopsManProps {
  title?: string;
  type?: 'shrug' | 'oops';
  children?: React.ReactNode;
}

const ErrorPerson: React.FC<OopsManProps> = ({ children, title, type = 'oops' }) => (
  <IonCard css={OopsManCss.card}>
    {title && (
      <IonCardHeader>
        <IonCardTitle>
          <Title level={1}>{title}</Title>
        </IonCardTitle>
      </IonCardHeader>
    )}
    <IonCardContent>
      <IonGrid>
        <IonRow>
          <IonCol size="12" css={OopsManCss.centeredText}>
            {type === 'oops' ? <OopsImg /> : <ShrugImg />}
            <div css={OopsManCss.attribution}>
              <IonButton fill="clear" size="small" href="https://storyset.com/home" target="_blank">
                <IonText css={commonCss.extraSmallText}>Home illustrations by Storyset</IonText>
              </IonButton>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol css={OopsManCss.centeredText}>{children}</IonCol>
        </IonRow>
      </IonGrid>
    </IonCardContent>
  </IonCard>
);

export default ErrorPerson;
