import React from 'react';

import { calcDatasourcePollutantInfo, DatasourcePollutantInfo, findAqiLevel } from '../shared/aqi';
import { decodeNetworkPreviewToken } from '../shared/data-preview';
import { LocationType } from '../shared/location-type';
import { SavedSettings } from '../shared/saved-settings';
import { ISettings } from '../shared/settings-interface';
import { SettingsKey } from '../shared/settings-key';
import { Viewport } from '../shared/viewport';
import { isWidgetMode } from '../shared/widgets-utils';
import {
  AirQualityMarkerDto,
  AqiLevelDto,
  AqiStandardId,
  AqiStandardV2Dto,
  DatasourceId,
  MapSettingsDto,
  MetricIndex,
  MetricKey,
  NetworkId,
  NetworkV2Dto,
  Point,
  PollutantConfigDto,
} from '../types';

interface SettingsContextType {
  setDatasourceId: (datasourceId: DatasourceId | null) => void;

  marker: AirQualityMarkerDto | null;
  setAvailableMarkers: (markers: AirQualityMarkerDto[] | null) => void;

  mapSettings: MapSettingsDto | null;
  setMapSettings: (mapSettings: MapSettingsDto | null) => void;

  network: NetworkV2Dto | null;
  setNetwork(network: NetworkV2Dto): void;
  networkId: NetworkId | null;

  aqiStandard: AqiStandardV2Dto | null;
  availableAqiStandards: AqiStandardV2Dto[] | null;
  setAvailableAqiStandards: (aqiStandards: AqiStandardV2Dto[]) => void;
  setAqiStandardId: (aqiStandardId: AqiStandardId) => void;

  myLocation: Point | null;
  setMyLocation: (location: Point | null, locationType?: LocationType) => void;

  searchedLocation: Point | null;
  setSearchedLocation: (location: Point | null) => void;

  lastSelectedLocationType: LocationType | null;
  lastSelectedLocation: Point | null;

  viewport: Viewport | null;
  setViewport: (viewport: Viewport | null) => void;

  loadSettings: (settingsStore: ISettings[]) => void;

  isLocationPermissionGranted: boolean | null;
  setLocationPermissionGranted: (granted: boolean) => void;

  // calculated values:
  pollutantConfigs: PollutantConfigDto[] | null;
  datasourcePollutantInfos: DatasourcePollutantInfo[] | null;
  aqiLevel: AqiLevelDto | null;
  aqiMetricKeys: MetricKey[] | null;
  savedSettings: SavedSettings | null;
}

const SettingsContext = React.createContext<SettingsContextType | undefined>(undefined);

interface SettingsProviderProps {
  children: React.ReactNode;
}

export const SettingsProvider: React.FC<SettingsProviderProps> = ({ children }) => {
  const [datasourceId, _setDatasourceId] = React.useState<DatasourceId | null>(null);
  const [availableMarkers, setAvailableMarkers] = React.useState<AirQualityMarkerDto[] | null>(null);
  const [network, _setNetwork] = React.useState<NetworkV2Dto | null>(null);
  const [availableAqiStandards, setAvailableAqiStandards] = React.useState<AqiStandardV2Dto[] | null>(null);
  const [networkId, setNetworkId] = React.useState<NetworkId | null>(null);
  const [aqiStandardId, _setAqiStandardId] = React.useState<AqiStandardId | null>(null);
  const [mapSettings, setMapSettings] = React.useState<MapSettingsDto | null>(null);
  const [viewport, setViewport] = React.useState<Viewport | null>(null);
  const [isLocationPermissionGranted, setLocationPermissionGranted] = React.useState<boolean | null>(null);
  const [myLocation, _setMyLocation] = React.useState<Point | null>(null);
  const [searchedLocation, _setSearchedLocation] = React.useState<Point | null>(null);
  const [lastSelectedLocationType, setLastSelectedLocationType] = React.useState<LocationType | null>(null);
  const [lastSelectedLocation, setLastSelectedLocation] = React.useState<Point | null>(null);
  const [isSettingsLoaded, setIsSettingsLoaded] = React.useState<boolean>(false);
  const [networkOverride, setNetworkOverride] = React.useState<Partial<NetworkV2Dto> | null>(null);
  const [aqiStandardOverride, setAqiStandardOverride] = React.useState<Partial<AqiStandardV2Dto> | null>(null);

  const isWidget = React.useMemo(() => {
    return isWidgetMode();
  }, []);

  const savedSettings = React.useMemo(() => {
    if (!networkId) {
      return null;
    }
    const settings = new SavedSettings(isWidget); // disabled if in widget mode
    if (settings.networkId !== networkId) {
      settings.clear();
      settings.networkId = networkId;
    }
    return settings;
  }, [networkId, isWidget]);

  const setDatasourceId = React.useCallback(
    (datasourceId: DatasourceId | null) => {
      _setDatasourceId(datasourceId);
      if (savedSettings) {
        savedSettings.datasourceId = datasourceId;
      }
    },
    [savedSettings]
  );

  const setAqiStandardId = React.useCallback(
    (aqiStandardId: AqiStandardId | null) => {
      _setAqiStandardId(aqiStandardId);
      if (savedSettings) {
        savedSettings.aqiStdId = aqiStandardId;
      }
    },
    [savedSettings]
  );

  const setSearchedLocation = React.useCallback(
    (location: Point | null) => {
      _setSearchedLocation(location);
      if (savedSettings) {
        savedSettings.searchedLocation = location;
      }
    },
    [savedSettings]
  );

  const loadSettings = React.useCallback(
    (settingsArray: ISettings[]) => {
      if (isSettingsLoaded) {
        return;
      }

      // Best here means the first non-null value
      function findBestSetting<T>(key: keyof ISettings) {
        return (settingsArray.find((s) => s[key] !== null)?.[key] as T | undefined) ?? null;
      }

      // The first non-empty setting is the one we want to use
      const referenceSettings = settingsArray.find((s) => !s.isEmpty());
      if (!referenceSettings) {
        // This should never happen
        return;
      }

      const { networkId, datasourceId, aqiStdId, searchedLatitude, searchedLongitude, preview } = referenceSettings;

      setNetworkId(networkId);
      _setDatasourceId(datasourceId);
      _setAqiStandardId(aqiStdId);

      if (searchedLatitude && searchedLongitude) {
        _setSearchedLocation({ type: 'Point', coordinates: [searchedLongitude, searchedLatitude] });
      } else {
        _setSearchedLocation(null);
      }

      const latitude = findBestSetting<number>(SettingsKey.latitude);
      const longitude = findBestSetting<number>(SettingsKey.longitude);
      const zoom = findBestSetting<number>(SettingsKey.zoom);
      if (latitude && longitude && zoom) {
        setViewport({ longitude, latitude, zoom });
      } else {
        // Should never happen
        setViewport(null);
      }

      if (preview) {
        const { network: networkOverride, aqiStandard: aqiStandardOverride } = decodeNetworkPreviewToken(preview);
        setNetworkOverride(networkOverride ?? null);
        setAqiStandardOverride(aqiStandardOverride ?? null);
      } else {
        setNetworkOverride(null);
        setAqiStandardOverride(null);
      }
      setIsSettingsLoaded(true);
    },
    [isSettingsLoaded]
  );

  const setNetwork = React.useCallback(
    (network: NetworkV2Dto | null) => {
      if (network && networkOverride) {
        network = { ...network, ...networkOverride };
      }
      _setNetwork(network);
      setNetworkId(network?.networkId ?? null);
    },
    [networkOverride]
  );

  const setMyLocation = React.useCallback((location: Point | null) => {
    _setMyLocation(location);
    if (location) {
      setLastSelectedLocationType(LocationType.MyLocation);
    }
  }, []);

  const marker = React.useMemo(() => {
    return availableMarkers?.find((m) => m.datasourceId === datasourceId) ?? null;
  }, [datasourceId, availableMarkers]);

  const aqiStandard = React.useMemo(() => {
    const aqiStandard = availableAqiStandards?.find((s) => s.aqiStandardId === aqiStandardId) ?? null;
    if (aqiStandard && aqiStandardOverride) {
      return { ...aqiStandard, ...aqiStandardOverride };
    }
    return aqiStandard;
  }, [aqiStandardId, availableAqiStandards, aqiStandardOverride]);

  const pollutantConfigs = React.useMemo(() => {
    if (!network || !aqiStandard) {
      return null;
    }
    return aqiStandard.pollutantConfigs.filter(({ pollutant }) => network.enabledPollutants.includes(pollutant));
  }, [network, aqiStandard]);

  const datasourcePollutantInfos = React.useMemo(() => {
    if (!marker || !pollutantConfigs || !aqiStandard) {
      return null;
    }
    return calcDatasourcePollutantInfo(marker.metrics, pollutantConfigs, aqiStandard);
  }, [marker, pollutantConfigs, aqiStandard]);

  const aqiLevel = React.useMemo(() => {
    if (!aqiStandard) {
      return null;
    }
    return findAqiLevel(aqiStandard, datasourcePollutantInfos?.[0].aqi);
  }, [aqiStandard, datasourcePollutantInfos]);

  const pollutantInfos = React.useMemo(() => {
    if (!pollutantConfigs) {
      return null;
    }
    return pollutantConfigs.map(({ pollutant, displayName, metricInfos }) => ({
      pollutant,
      displayName,
      aqiInfo: metricInfos.find((info) => info.indexType == MetricIndex.Aqi)!,
      concInfo: metricInfos.find((info) => info.indexType == MetricIndex.Unindexed)!,
    }));
  }, [pollutantConfigs]);

  const aqiMetricKeys = React.useMemo(() => {
    if (!pollutantInfos) {
      return null;
    }
    return pollutantInfos.map((p) => p.aqiInfo.key).flat();
  }, [pollutantInfos]);

  // Set the default aqi standard
  // Note: we can't use DefaultSettings for this because of a dependency cycle.
  React.useEffect(() => {
    if (!aqiStandardId && availableAqiStandards?.length) {
      _setAqiStandardId(availableAqiStandards[0].aqiStandardId);
    }
  }, [aqiStandardId, availableAqiStandards]);

  React.useEffect(() => {
    if (myLocation) {
      setLastSelectedLocationType(LocationType.MyLocation);
      setLastSelectedLocation(myLocation);
    } else if (searchedLocation) {
      setLastSelectedLocationType(LocationType.SearchedLocation);
      setLastSelectedLocation(searchedLocation);
    } else {
      setLastSelectedLocationType(null);
      setLastSelectedLocation(null);
    }
  }, [myLocation, searchedLocation]);

  // Save viewport settings
  React.useEffect(() => {
    if (!savedSettings || !viewport) {
      return;
    }

    // Throttle the updates to once every 500ms using a timeout
    const timeoutId = setTimeout(() => {
      // Save the settings
      savedSettings.latitude = viewport.latitude;
      savedSettings.longitude = viewport.longitude;
      savedSettings.zoom = viewport.zoom;
    }, 500);

    // Cleanup
    return () => {
      clearTimeout(timeoutId);
    };
  }, [viewport, savedSettings]);

  return (
    <SettingsContext.Provider
      value={{
        marker,
        network,
        setNetwork,
        aqiStandard,
        availableAqiStandards,
        setAvailableAqiStandards,
        setAqiStandardId,
        pollutantConfigs,
        datasourcePollutantInfos,
        aqiLevel,
        networkId,
        aqiMetricKeys,
        mapSettings,
        setMapSettings,
        savedSettings,
        myLocation,
        setMyLocation,
        searchedLocation,
        setSearchedLocation,
        lastSelectedLocationType,
        lastSelectedLocation,
        viewport,
        setViewport,
        loadSettings,
        setDatasourceId,
        setAvailableMarkers,
        isLocationPermissionGranted,
        setLocationPermissionGranted,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  const context = React.useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};
