import { useAppSettings } from './hooks/app-settings';
import { getBuildVersion } from './shared/build-version';

export function installAppConsole() {
  const { options, setOption } = useAppSettings();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).wlom = {
    version: getBuildVersion(),
    settings: {
      current: options,
      set: setOption,
    },
  };
}
