import chroma from 'chroma-js';
import {
  AirQualityClassificationType,
  AqiStandardId,
  MetricIndex,
  MetricInfoDto,
  Pollutant,
  type AqiLevelDto,
  type AqiLevelId,
  type AqiStandardV2Dto,
  type MetricKey,
  type PollutantConfigDto,
} from '../types';

export const GREY_COLOR = '#bbbbbb';

export const UNKNOWN_AQI_LEVEL: AqiLevelDto = {
  aqiLevelId: 'unknown' as AqiLevelId,
  description: 'Unknown',
  rangeStart: -1,
  rangeEnd: -1,
  color: GREY_COLOR,
  recommendations: [],
  iconUrl: '/bucket-assets/aqi_icons/moderate.svg',
};

export function findAqiLevel(aqiStandard: AqiStandardV2Dto, aqi: number | null | undefined): AqiLevelDto {
  if (aqi === undefined || aqi === null) {
    return UNKNOWN_AQI_LEVEL;
  }
  const { aqiLevels } = aqiStandard;
  const firstLevel = aqiLevels[0];
  if (aqi <= firstLevel.rangeStart) {
    return aqiLevels[0];
  }
  const lastLevel = aqiLevels[aqiLevels.length - 1];
  if (aqi >= lastLevel.rangeEnd) {
    return lastLevel;
  }
  return aqiLevels.find((level) => {
    return aqi >= level.rangeStart && aqi <= level.rangeEnd;
  })!;
}

export function htmlColorToRgbArray(hexColor: string): [number, number, number] {
  return chroma(hexColor).rgb();
}

export function getAqiRgbColor(aqiStandard: AqiStandardV2Dto, aqi?: number): [number, number, number] {
  const aqiLevel = findAqiLevel(aqiStandard, aqi);
  return htmlColorToRgbArray(aqiLevel.color);
}

export class DatasourcePollutantInfo {
  pollutant: Pollutant;
  metricValue: number | undefined; // The aqi/concentration value before conversion
  aqi: number | undefined;
  aqiInfo: MetricInfoDto;
  concInfo: MetricInfoDto;
  pollutantDisplayName: string;
}

export function calcDatasourcePollutantInfo(
  metrics: Record<MetricKey, number | undefined>,
  pollutantConfigs: PollutantConfigDto[],
  aqiStandard: AqiStandardV2Dto
): DatasourcePollutantInfo[] {
  return pollutantConfigs
    .map(({ metricInfos, pollutant, displayName }) => {
      const aqiInfo = metricInfos.find((info) => info.indexType === MetricIndex.Aqi)!;
      const concInfo = metricInfos.find((info) => info.indexType === MetricIndex.Unindexed)!;
      const metricValue = metrics[aqiInfo.key];
      const aqi = maybeConvertAqcToAqi(aqiStandard, pollutant, metricValue);
      return { pollutant, metricValue, aqi, aqiInfo, concInfo, pollutantDisplayName: displayName };
    })
    .sort((a, b) => (b.aqi ?? -1) - (a.aqi ?? -1));
}

export function joinWithCommasAnd(texts: string[]): string {
  switch (texts.length) {
    case 0:
      return '';
    case 1:
      return texts[0];
    case 2:
      return texts.join(' and ');
    default:
      // Join all but the last element with commas, then add 'and' before the last element
      return `${texts.slice(0, -1).join(', ')}, and ${texts[texts.length - 1]}`;
  }
}

export function maybeConvertAqcToAqi(aqiStandard: AqiStandardV2Dto, pollutant: Pollutant, aqc?: number) {
  /*
   * Uses the defined AQI standard levels to convert an AQC value to AQI.
   * The generated AQI is non-standard but is useful for plotting charts and comparing
   * between different pollutants. Since most of the time we want to report the AQC or the category,
   * this value is not available to the end user.
   * This function is always safe to call, even with an AQI value from an AQI standard.
   */
  if (aqiStandard.aqiClassType === AirQualityClassificationType.AQI || aqc === undefined || aqc === null) {
    return aqc;
  }

  if (aqiStandard.aqiClassType === AirQualityClassificationType.AQC && aqiStandard.name !== 'AU NSW AQC') {
    throw new Error('Unsupported AQC standard');
  }

  // Note: this is hard-coded for this particular standard, but once we are happy with this
  // we can move it to Combined Measurements and Metrics Dictionary.
  // Source: https://www.airquality.nsw.gov.au/health-advice/air-quality-categories
  const CONC_LEVELS_BY_POLLUTANT: Record<Pollutant, [number, number][]> = {
    [Pollutant.PM2_5]: [
      [0, 25],
      [26, 50],
      [51, 100],
      [101, 300],
      [301, 1000],
    ],
    [Pollutant.NO2]: [
      [0, 80],
      [81, 120],
      [121, 180],
      [181, 240],
      [241, 300],
    ],
    // O3, PM10, and CO are also available.
  };

  const concLevels = CONC_LEVELS_BY_POLLUTANT[pollutant];
  if (!concLevels) {
    throw new Error('Unsupported pollutant');
  }

  // Find the level given the AQC
  const firstConcLevel = concLevels[0];
  const lastConcLevel = concLevels[concLevels.length - 1];
  let lvlIdx = 0;
  if (aqc < firstConcLevel[0]) {
    lvlIdx = 0;
  } else if (aqc > lastConcLevel[1]) {
    lvlIdx = concLevels.length - 1;
  } else {
    lvlIdx = concLevels.findIndex(([s, e]) => {
      // This works because AQC values are always integers.
      return aqc >= s && aqc <= e;
    })!;
  }
  const concLevel = concLevels[lvlIdx];

  // A value between 0 and 1 within the range of the category.
  const normalizedAqc = (aqc - concLevel[0]) / (concLevel[1] - concLevel[0]);

  // Calculates the AQI by linearly interpolating the AQI range.
  const aqiLevel = aqiStandard.aqiLevels[lvlIdx];
  const aqi = normalizedAqc * (aqiLevel.rangeEnd - aqiLevel.rangeStart) + aqiLevel.rangeStart;
  return Math.max(aqi, aqiLevel.rangeStart);
}

function isPositiveStandard(aqiStdId: AqiStandardId) {
  // Some standards have levels that go from "good" to "bad" as the AQI increases,
  // while others go from "bad" to "good", such as the UK DAQI.

  if (aqiStdId === 'UK-DEFRA') {
    // Goes from "low" to "very high" pollution
    return false;
  }

  return true;
}

export function getAqText(aqiStdId: AqiStandardId): string {
  return isPositiveStandard(aqiStdId) ? 'Air Quality' : 'Air Pollution';
}
