import { UndefinedInitialDataOptions } from '@tanstack/react-query';

export function createQueryConfig<T>(staleTime?: number): Partial<UndefinedInitialDataOptions<T>> {
  staleTime ??= 1000 * 60 * 30; // 30 minutes
  return {
    // We never retry queries to avoid stampeding the server when
    // there's a problem.
    retry: false,
    // Refetch right after the data is considered stale.
    staleTime,
    refetchInterval: staleTime,
    // Refetch when the window regains focus.
    // What this means is that if you go to another tab and come back,
    // the data will be refetched if it's stale.
    refetchOnWindowFocus: true,
    // Don't refetch when the app is in the background (e.g. tab is not visible).
    refetchIntervalInBackground: false,
  };
}
