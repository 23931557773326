import { IonBackButton, IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { Title } from '../Title';

interface PageHeaderProps {
  title: string;
  defaultHref?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, defaultHref = '/' }) => {
  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton color="medium" defaultHref={defaultHref} />
        </IonButtons>
        <IonTitle>
          <Title>{title}</Title>
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default PageHeader;
