import { AqiStandardId, DatasourceId, NetworkId, Point } from '../types';
import { PreviewToken } from './data-preview';
import { ISettings } from './settings-interface';
import { SettingsKey } from './settings-key';

export class SavedSettings implements ISettings {
  KEY_PREFIX = 'wlom:settings';

  constructor(private readonly disabled = false) {}

  get(key: SettingsKey) {
    return localStorage.getItem(`${this.KEY_PREFIX}:${key}`);
  }

  set(key: SettingsKey, value: string) {
    if (!this.disabled) {
      localStorage.setItem(`${this.KEY_PREFIX}:${key}`, value);
    }
  }

  remove(key: SettingsKey) {
    if (!this.disabled) {
      localStorage.removeItem(`${this.KEY_PREFIX}:${key}`);
    }
  }

  clear() {
    Object.values(SettingsKey).forEach((key) => {
      localStorage.removeItem(`${this.KEY_PREFIX}:${key}`);
    });
  }

  get networkId(): NetworkId | null {
    return this.get(SettingsKey.networkId) as NetworkId | null;
  }

  get latitude(): number | null {
    const latitude = this.get(SettingsKey.latitude);
    return latitude ? parseFloat(latitude) : null;
  }

  get longitude(): number | null {
    const longitude = this.get(SettingsKey.longitude);
    return longitude ? parseFloat(longitude) : null;
  }

  get zoom(): number | null {
    const zoom = this.get(SettingsKey.zoom);
    return zoom ? parseFloat(zoom) : null;
  }

  get datasourceId(): DatasourceId | null {
    return this.get(SettingsKey.datasourceId) as DatasourceId | null;
  }

  get searchedLatitude(): number | null {
    const latitude = this.get(SettingsKey.searchedLatitude);
    return latitude ? parseFloat(latitude) : null;
  }

  get searchedLongitude(): number | null {
    const longitude = this.get(SettingsKey.searchedLongitude);
    return longitude ? parseFloat(longitude) : null;
  }

  get searchedLocation(): Point | null {
    if (this.searchedLatitude && this.searchedLongitude) {
      return {
        type: 'Point',
        coordinates: [this.searchedLongitude, this.searchedLatitude],
      };
    }
    return null;
  }

  get aqiStdId(): AqiStandardId | null {
    return this.get(SettingsKey.aqiStdId) as AqiStandardId | null;
  }

  get preview(): PreviewToken | null {
    return null;
  }

  set networkId(value: NetworkId) {
    this.set(SettingsKey.networkId, value);
  }

  set latitude(value: number) {
    this.set(SettingsKey.latitude, value.toString());
  }

  set longitude(value: number) {
    this.set(SettingsKey.longitude, value.toString());
  }

  set zoom(value: number) {
    this.set(SettingsKey.zoom, value.toString());
  }

  set datasourceId(value: DatasourceId | null) {
    if (value === null) {
      this.remove(SettingsKey.datasourceId);
    } else {
      this.set(SettingsKey.datasourceId, value);
    }
  }

  set searchedLocation(value: Point | null) {
    if (value === null) {
      this.remove(SettingsKey.searchedLatitude);
      this.remove(SettingsKey.searchedLongitude);
    } else {
      this.set(SettingsKey.searchedLatitude, value.coordinates[1].toString());
      this.set(SettingsKey.searchedLongitude, value.coordinates[0].toString());
    }
  }

  set aqiStdId(value: AqiStandardId | null) {
    if (value === null) {
      this.remove(SettingsKey.aqiStdId);
    } else {
      this.set(SettingsKey.aqiStdId, value);
    }
  }

  isEmpty(): boolean {
    return Object.values(SettingsKey).every((key) => !this.get(key));
  }
}
