import { decode } from 'url-safe-base64';
import { AqiStandardV2Dto, Brand, NetworkV2Dto } from '../types';

export type PreviewToken = Brand<string, 'PreviewToken'>;

interface DataPreview {
  network?: Partial<NetworkV2Dto>;
  aqiStandard?: Partial<AqiStandardV2Dto>;
}

export function decodeNetworkPreviewToken(token: PreviewToken): DataPreview {
  const jsonString = atob(decode(token));
  return JSON.parse(jsonString);
}
