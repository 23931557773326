import { css } from '@emotion/react';
import React from 'react';

interface TitleProps {
  children: React.ReactNode;
  level?: 1 | 2;
  color?: string;
  fontSize?: string;
}

const titleCss = [
  // H1
  css({
    fontFamily: "'Poppins-Bold', sans-serif",
    fontSize: 'min(5vw, 1.25rem)',
    fontWeight: 500,
    color: 'var(--ion-color-secondary)',
  }),
  // H2
  css({
    fontFamily: "'Nunito-Bold', sans-serif",
    fontSize: 'min(5vw, 0.9375rem)',
    color: 'var(--ion-color-secondary)',
  }),
];

export const Title: React.FC<TitleProps> = ({ children, color, level = 1, fontSize }) => {
  // const H = `h${level}` as keyof JSX.IntrinsicElements;
  const cssRules = [titleCss[level - 1]];
  const extraRule: Record<string, string> = {};
  if (color) {
    extraRule['color'] = color;
  }
  if (fontSize) {
    extraRule['fontSize'] = fontSize;
  }
  if (extraRule) {
    cssRules.push(css(extraRule));
  }

  return <span css={cssRules}>{children}</span>;
};
