export enum SettingsKey {
  networkId = 'networkId',
  datasourceId = 'datasourceId',
  latitude = 'latitude',
  longitude = 'longitude',
  zoom = 'zoom',
  searchedLatitude = 'searchedLatitude',
  searchedLongitude = 'searchedLongitude',
  aqiStdId = 'aqiStdId',
  preview = 'preview',
  disableTour = 'disableTour',
}
