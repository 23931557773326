// UserContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, isSignInWithEmailLink, signInWithEmailLink, User } from 'firebase/auth';
import { useGetFollows } from '../api/queries/getFollows';
import { auth } from '../firebaseConfig';
import { FollowDto } from '../types';

interface UserContextProps {
  user: User | null;
  idToken: string | null;
  isUserLoading: boolean;
  isFollowDataLoading: boolean;
  follows: FollowDto[] | null | undefined;
}

const UserContext = createContext<UserContextProps>({
  user: null,
  idToken: null,
  follows: undefined,
  isFollowDataLoading: true,
  isUserLoading: true,
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [idToken, setIdToken] = useState<string | null>(null);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(true);
  const { data: fData, isFetching: isFollowDataLoading } = useGetFollows({ firebaseToken: idToken });

  useEffect(() => {
    const handleSignIn = async () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          email = window.prompt('Please provide your email for confirmation');
        }
        try {
          await signInWithEmailLink(auth, email!, window.location.href);
          window.localStorage.removeItem('emailForSignIn');
          window.history.replaceState({}, document.title, window.location.pathname);
        } catch (error) {
          console.error('Error signing in with email link', error);
        }
      }
    };
    handleSignIn();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      setIsUserLoading(false);
      if (user) {
        try {
          const idToken = await user.getIdToken();
          setIdToken(idToken);
        } catch (error) {
          setIdToken(null);
          console.error('Error getting ID token:', error);
        }
      } else {
        setIdToken(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ user, idToken, follows: fData, isFollowDataLoading, isUserLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
