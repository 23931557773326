import { css } from '@emotion/react';

// Used for the maximum font size in the ReactFitty component.
export const MAX_FONT_SIZE = 20;
export const EXTRA_SMALL_FONT_SIZE_REM = 0.6;
export const SMALL_FONT_SIZE_REM = 0.8;
export const LARGE_FONT_SIZE_REM = 1.05;

export enum Breakpoints {
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
}

export enum MediaQueries {
  sm = `@media (max-width: ${Breakpoints.sm}px)`,
  md = `@media (max-width: ${Breakpoints.md}px)`,
  lg = `@media (max-width: ${Breakpoints.lg}px)`,
  xl = `@media (max-width: ${Breakpoints.xl}px)`,
}

export const centered = css({
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  margin: 'auto',
});

export const extraSmallText = css({
  fontSize: `${EXTRA_SMALL_FONT_SIZE_REM}rem`,
});

export const smallText = css({
  fontSize: `${SMALL_FONT_SIZE_REM}rem`,
});

export const largeText = css({
  fontSize: `${LARGE_FONT_SIZE_REM}rem`,
});

export const bold = css({
  fontWeight: 'bold',
});

export const italics = css({
  fontStyle: 'italic',
});

export const roundedCorners = css({
  borderRadius: 4,
});

export const noPadding = css({
  padding: 0,
});

export const textAlign = {
  start: css({
    textAlign: 'start',
  }),
  end: css({
    textAlign: 'end',
  }),
};
