import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PostHogProvider } from 'posthog-js/react';
import { MapProvider } from 'react-map-gl/maplibre';
import { Route as ReactRoute, Switch } from 'react-router-dom';

import { css } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import React, { Suspense } from 'react';
import { safeLazy } from 'react-safe-lazy';
import { installAppConsole } from './app-console';
import { RedirectHandler } from './components/RedirectHandler';
import SentryErrorBoundary from './components/SentryErrorBoundary';
import { SettingsProvider } from './contexts/SettingsContext';
import { UserProvider } from './contexts/UserContext';
import { isWidgetMode } from './shared/widgets-utils';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'; // ensure consistent rendering across browsers
import '@ionic/react/css/structure.css'; // ensure scrolling behaves like native
import '@ionic/react/css/typography.css'; // necessary for dynamic font scaling
import './theme/variables.css'; // theme variables

setupIonicReact();
installAppConsole();

const devToolCss = css({
  ['.tsqd-open-btn-container']: {
    top: '30px',
  },
});

const history = createBrowserHistory();
const isLocal = window.location.hostname === 'localhost';
const isProduction = process.env.NODE_ENV === 'production';

if (!isLocal) {
  const isDevString = localStorage.getItem('wlom:isDev') ?? 'false';
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    release: 'openmap-ionic@' + import.meta.env.VITE_BUILD_NUMBER,
    integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
    tunnel: '/proxy/monitoring',
    tracesSampler() {
      if (isDevString === 'true' && isProduction) {
        return 0.0;
      }
      return 1.0; // 100% of transactions
    },
  });

  Sentry.setTag('isDev', isDevString);
}

const Route = isLocal ? ReactRoute : Sentry.withSentryRouting(ReactRoute);

// Lazy load all pages
const MapPage = safeLazy(() => import('./pages/MapPage'));
const CreateWidgetPage = safeLazy(() => import('./pages/CreateWidgetPage'));
const MapWidget = safeLazy(() => import('./widgets/MapWidget'));
const CurrentAQWidget = safeLazy(() => import('./widgets/CurrentAQWidget'));
const LoginPage = safeLazy(() => import('./pages/LoginPage'));
const BombPage = safeLazy(() => import('./pages/BombPage'));
const NetworkNotFoundPage = safeLazy(() => import('./pages/NetworkNotFoundPage'));
const NotFoundPage = safeLazy(() => import('./pages/NotFoundPage'));
const SelectNetworkPage = safeLazy(() => import('./pages/SelectNetworkPage'));
const NotificationPreferencesPage = safeLazy(() => import('./pages/NotificationPreferencesPage'));

const App: React.FC = () => {
  const isWidget = isWidgetMode();
  return (
    <IonApp>
      <SentryErrorBoundary>
        <QueryClientProvider client={new QueryClient()}>
          <SettingsProvider>
            <PostHogProvider
              apiKey={import.meta.env.VITE_POSTHOG_API_KEY}
              options={{ api_host: import.meta.env.VITE_POSTHOG_HOST }}
            >
              <MapProvider>
                {!isWidget && (
                  <div css={devToolCss}>
                    <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" />
                  </div>
                )}
                <UserProvider>
                  <IonReactRouter history={history}>
                    <IonRouterOutlet>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Switch>
                          {/* --- Widgets --- */}
                          <Route path="/:networkId/create-widget" exact component={CreateWidgetPage} />
                          <Route path="/:networkId/widgets/v1/map" exact component={MapWidget} />
                          <Route path="/:networkId/widgets/v1/current-air-quality" exact component={CurrentAQWidget} />
                          {/* --- SPA --- */}
                          <Route path="/login" exact component={LoginPage} />
                          {!isProduction && <Route path="/bomb" exact component={BombPage} />}
                          <Route path="/errors/network-not-found" exact component={NetworkNotFoundPage} />
                          <Route path="/errors/not-found" exact component={NotFoundPage} />
                          <Route path="/networks" exact component={SelectNetworkPage} />
                          <Route path="/notifications/preferences" exact component={NotificationPreferencesPage} />
                          <Route path="/:networkId" exact component={MapPage} />
                          <Route path="/:networkId/datasources/:datasourceId" exact component={MapPage} />
                          <Route path="/:networkId/ds/:datasourceId" exact component={MapPage} />
                          <Route path="/" exact component={() => <RedirectHandler url="/world" />} />
                          <Route component={() => <RedirectHandler url="/errors/not-found" />} /> {/* Fallback */}
                        </Switch>
                      </Suspense>
                    </IonRouterOutlet>
                  </IonReactRouter>
                </UserProvider>
              </MapProvider>
            </PostHogProvider>
          </SettingsProvider>
        </QueryClientProvider>
      </SentryErrorBoundary>
    </IonApp>
  );
};

export default App;
