import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { apiClient } from '../apiClient';
import { GetFollowsResponseDto, FollowDto } from '../../types';
import { createQueryConfig } from '../config';

export const useGetFollows = ({
  firebaseToken,
}: {
  firebaseToken: string | null;
}): UseQueryResult<FollowDto[] | null> => {
  return useQuery({
    queryKey: ['follows', firebaseToken],
    queryFn: async () => {
      if (!firebaseToken) {
        return null;
      }
      const { data } = await apiClient.get<GetFollowsResponseDto>('/v1/follows', {
        headers: {
          Authorization: `Bearer ${firebaseToken}`,
        },
      });
      return data.data.follows;
    },
    // Don't refetch too often because this hook is used in a Provider
    // and is always mounted.
    ...createQueryConfig(1000 * 60 * 60),
  });
};
