import * as React from "react";
const SvgShrug = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 500 500", ...props }, /* @__PURE__ */ React.createElement("g", { id: "freepik--background-complete--inject-222" }, /* @__PURE__ */ React.createElement("rect", { y: 382.4, width: 500, height: 0.25, style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("rect", { x: 416.78, y: 398.49, width: 33.12, height: 0.25, style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("rect", { x: 322.53, y: 401.21, width: 8.69, height: 0.25, style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("rect", { x: 396.59, y: 389.21, width: 19.19, height: 0.25, style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("rect", { x: 52.46, y: 390.89, width: 43.19, height: 0.25, style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("rect", { x: 104.56, y: 390.89, width: 6.33, height: 0.25, style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("rect", { x: 131.47, y: 395.11, width: 93.68, height: 0.25, style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("path", { d: "M237,337.8H43.91a5.71,5.71,0,0,1-5.7-5.71V60.66A5.71,5.71,0,0,1,43.91,55H237a5.71,5.71,0,0,1,5.71,5.71V332.09A5.71,5.71,0,0,1,237,337.8ZM43.91,55.2a5.46,5.46,0,0,0-5.45,5.46V332.09a5.46,5.46,0,0,0,5.45,5.46H237a5.47,5.47,0,0,0,5.46-5.46V60.66A5.47,5.47,0,0,0,237,55.2Z", style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("path", { d: "M453.31,337.8H260.21a5.72,5.72,0,0,1-5.71-5.71V60.66A5.72,5.72,0,0,1,260.21,55h193.1A5.71,5.71,0,0,1,459,60.66V332.09A5.71,5.71,0,0,1,453.31,337.8ZM260.21,55.2a5.47,5.47,0,0,0-5.46,5.46V332.09a5.47,5.47,0,0,0,5.46,5.46h193.1a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z", style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("rect", { x: 222.09, y: 106.18, width: 91.95, height: 207.14, style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("rect", { x: 168.39, y: 167.24, width: 199.37, height: 85.01, transform: "translate(477.81 -58.32) rotate(90)", style: {
  fill: "#fafafa"
} }), /* @__PURE__ */ React.createElement("rect", { x: 267.09, y: 109.06, width: 1.95, height: 202.31, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 103.48, width: 96.19, height: 9.75, style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 114.31, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 116.93, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 119.55, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 122.18, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 124.8, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 127.42, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 130.04, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 132.67, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 135.29, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 137.91, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 140.53, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 143.16, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 145.78, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 148.4, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 151.02, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 153.65, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 156.27, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 219.97, y: 158.89, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 330.95, y: 106.18, width: 91.95, height: 207.14, style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("rect", { x: 277.25, y: 167.24, width: 199.37, height: 85.01, transform: "translate(586.67 -167.18) rotate(90)", style: {
  fill: "#fafafa"
} }), /* @__PURE__ */ React.createElement("rect", { x: 375.95, y: 109.06, width: 1.95, height: 202.31, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 103.48, width: 96.19, height: 9.75, style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 114.31, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 116.93, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 119.55, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 122.18, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 124.8, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 127.42, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 130.04, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 132.67, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 135.29, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 137.91, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 140.53, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 143.16, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 145.78, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 148.4, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 151.02, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 153.65, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 156.27, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 328.83, y: 158.89, width: 96.19, height: 1.16, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 84.7, y: 122.32, width: 89.91, height: 115.7, transform: "translate(309.83 50.51) rotate(90)", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("rect", { x: 73.41, y: 136.82, width: 112.49, height: 86.7, transform: "translate(259.31 360.34) rotate(180)", style: {
  fill: "#ebebeb"
} }), /* @__PURE__ */ React.createElement("rect", { x: 127.27, y: 152.48, width: 1, height: 12.09, style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "100.78 203.49 99.78 203.49 99.78 164.07 151.81 164.07 151.81 186.9 150.81 186.9 150.81 165.07 100.78 165.07 100.78 203.49", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "166.66 199.19 165.66 199.19 165.66 187.4 138.6 187.4 138.6 198.78 137.6 198.78 137.6 186.4 166.66 186.4 166.66 199.19", style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("rect", { x: 114.87, y: 148.04, width: 25.81, height: 8.88, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 139.39, y: 169.35, width: 24.6, height: 9.65, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 129.66, y: 193.8, width: 16.9, height: 9.96, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 157.11, y: 195.41, width: 18.09, height: 7.56, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 88.4, y: 171.4, width: 27.95, height: 12.89, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 91.35, y: 198.78, width: 17.85, height: 9.42, style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 125.05, y: 162.16, width: 5.45, height: 5.45, transform: "translate(154.01 -42.05) rotate(45)", style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 148.58, y: 184.17, width: 5.45, height: 5.45, transform: "translate(176.47 -52.25) rotate(45)", style: {
  fill: "#f5f5f5"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 129.66, cy: 106.26, r: 16.3, style: {
  fill: "#e0e0e0"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 129.66, cy: 106.26, r: 13.86, style: {
  fill: "#f0f0f0"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "130.31 96.75 130.31 106.44 136.73 102.81", style: {
  fill: "#f0f0f0"
} }), /* @__PURE__ */ React.createElement("path", { d: "M130.31,107.44a1,1,0,0,1-.5-.13,1,1,0,0,1-.5-.87V96.75a1,1,0,0,1,2,0v8l4.93-2.79a1,1,0,0,1,1,1.74l-6.43,3.63A1,1,0,0,1,130.31,107.44Z", style: {
  fill: "#e0e0e0"
} })), /* @__PURE__ */ React.createElement("g", { id: "freepik--Shadow--inject-222" }, /* @__PURE__ */ React.createElement("ellipse", { id: "freepik--path--inject-222", cx: 250, cy: 416.24, rx: 193.89, ry: 11.32, style: {
  fill: "#f5f5f5"
} })), /* @__PURE__ */ React.createElement("g", { id: "freepik--Plant--inject-222" }, /* @__PURE__ */ React.createElement("path", { d: "M98.47,387.31l-.94,0c0-1.4,1.16-34.45,6.91-40,5.91-5.76,12.86-.09,12.93,0l-.61.73c-.25-.22-6.36-5.18-11.66,0C99.62,353.25,98.48,387,98.47,387.31Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M98,393.94l-.94-.09c0-.4,3.71-40.12-10.68-56.63l.72-.62C101.75,353.42,98,393.54,98,393.94Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M98.46,389.7c-.13-1.88-2.9-46,8.16-56.19l.64.69c-10.73,9.87-7.89,55-7.86,55.44Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M97.06,389.67c0-.3-.35-30-8.21-34.47a5,5,0,0,0-4.58-.47c-4.62,1.9-7.07,10.71-7.09,10.8l-.91-.25c.1-.38,2.59-9.34,7.64-11.43a5.92,5.92,0,0,1,5.41.53c8.33,4.77,8.67,34,8.68,35.28Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M100.29,389.7c-.05-1-1.27-23.45,5.11-31.77,1.69-2.21,3.57-3.27,5.61-3.12,4.53.32,8,6.46,8.2,6.72l-.83.46c0-.06-3.41-6-7.43-6.24-1.7-.12-3.32.81-4.8,2.75-6.17,8.05-4.93,30.91-4.91,31.14Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M99,392.81l-.94-.07c0-.54,4-54.13-2.59-63.06l.76-.57C103.06,338.33,99.19,390.59,99,392.81Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M82.07,359.19s4.09,1.5,2.87,5.06-14.12,9.4-14.12,9.4-5.41-5-.45-13S82,356.64,82.07,359.19Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M82.07,359.19s4.09,1.5,2.87,5.06-14.12,9.4-14.12,9.4-5.41-5-.45-13S82,356.64,82.07,359.19Z", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.4
} }), /* @__PURE__ */ React.createElement("path", { d: "M99.82,352.42s-4.35-.18-4.59,3.57,9.42,14.11,9.42,14.11,6.89-2.49,5.41-11.84S100.9,350.12,99.82,352.42Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M99.82,352.42s-4.35-.18-4.59,3.57,9.42,14.11,9.42,14.11,6.89-2.49,5.41-11.84S100.9,350.12,99.82,352.42Z", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.4
} }), /* @__PURE__ */ React.createElement("path", { d: "M103.5,334.49s-2.9-.32-2.66-2.08,11.27-6.31,17.78,1c0,0-4.17,7.16-10.2,7.25S103.5,334.49,103.5,334.49Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M103.5,334.49s-2.9-.32-2.66-2.08,11.27-6.31,17.78,1c0,0-4.17,7.16-10.2,7.25S103.5,334.49,103.5,334.49Z", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.4
} }), /* @__PURE__ */ React.createElement("path", { d: "M98.1,330.2s2.63,1.27,3.36-.35-6.22-11.32-15.64-8.53c0,0-.25,8.29,4.82,11.55S98.1,330.2,98.1,330.2Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M98.1,330.2s2.63,1.27,3.36-.35-6.22-11.32-15.64-8.53c0,0-.25,8.29,4.82,11.55S98.1,330.2,98.1,330.2Z", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.4
} }), /* @__PURE__ */ React.createElement("path", { d: "M111.83,357.76s-2-1.62.15-2.4,12.76-.57,12.87,9.93c0,0-12.34,2-13.87-2S111.83,357.76,111.83,357.76Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M111.83,357.76s-2-1.62.15-2.4,12.76-.57,12.87,9.93c0,0-12.34,2-13.87-2S111.83,357.76,111.83,357.76Z", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.4
} }), /* @__PURE__ */ React.createElement("path", { d: "M86.67,334.22s2-1.61-.16-2.4-12.76-.57-12.87,9.93c0,0,12.34,2.05,13.87-1.95S86.67,334.22,86.67,334.22Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M86.67,334.22s2-1.61-.16-2.4-12.76-.57-12.87,9.93c0,0,12.34,2.05,13.87-1.95S86.67,334.22,86.67,334.22Z", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.4
} }), /* @__PURE__ */ React.createElement("path", { d: "M93.19,342.68s.17-2-1.41-1.2-7.11,6.73-1.38,12.38c0,0,7.7-5.7,6.31-8.68S93.19,342.68,93.19,342.68Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M93.19,342.68s.17-2-1.41-1.2-7.11,6.73-1.38,12.38c0,0,7.7-5.7,6.31-8.68S93.19,342.68,93.19,342.68Z", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.4
} }), /* @__PURE__ */ React.createElement("path", { d: "M113.61,345.47s-1.19-1.56.56-1.76,9.6,1.9,7.77,9.74c0,0-9.56-.72-10-4S113.61,345.47,113.61,345.47Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M113.61,345.47s-1.19-1.56.56-1.76,9.6,1.9,7.77,9.74c0,0-9.56-.72-10-4S113.61,345.47,113.61,345.47Z", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.4
} }), /* @__PURE__ */ React.createElement("polygon", { points: "82.78 418.76 113.7 418.76 117.81 388.69 78.68 388.69 82.78 418.76", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "82.78 418.76 113.7 418.76 117.81 388.69 78.68 388.69 82.78 418.76", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("path", { d: "M121.68,386.26v2.85a3.45,3.45,0,0,1-3.46,3.46H77.87a3.45,3.45,0,0,1-3.46-3.46v-2.85Z", style: {
  fill: "#263238"
} })), /* @__PURE__ */ React.createElement("g", { id: "freepik--trash-bin--inject-222" }, /* @__PURE__ */ React.createElement("path", { d: "M372.37,392.28a3.18,3.18,0,0,1,2.55-4.56,6.8,6.8,0,0,1,2.15-7.44,1.63,1.63,0,0,1,.94-.41,1.77,1.77,0,0,1,1,.44l6,4.28a3.63,3.63,0,0,1,1.18,1.16c1.22,2.36-3.17,5.38-1.37,7.33.52.56,1.36.67,2,1.08,1.42.88,1.59,2.83,2.25,4.36s3,2.73,3.85,1.31c-.65-1.47,1.31-2.74,2.91-2.93s3.27-.27,4.15-1.77c2.27,2,7.35-3.55,7.23-5.78,1.87.14,3.14,1.89,3.55,3.63a26.43,26.43,0,0,1,.19,5.56c.06,3.61,1.22,7.15,1.16,10.76-.05,2.81-1.14,5.93-3.71,7.06a9.93,9.93,0,0,1-4,.54l-29.3-.19a4.64,4.64,0,0,1-2.06-.34c-1.32-.69-1.6-2.43-1.72-3.92q-.78-9.54-1.57-19.09Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M372.37,392.28a3.18,3.18,0,0,1,2.55-4.56,6.8,6.8,0,0,1,2.15-7.44,1.63,1.63,0,0,1,.94-.41,1.77,1.77,0,0,1,1,.44l6,4.28a3.63,3.63,0,0,1,1.18,1.16c1.22,2.36-3.17,5.38-1.37,7.33.52.56,1.36.67,2,1.08,1.42.88,1.59,2.83,2.25,4.36s3,2.73,3.85,1.31c-.65-1.47,1.31-2.74,2.91-2.93s3.27-.27,4.15-1.77c2.27,2,7.35-3.55,7.23-5.78,1.87.14,3.14,1.89,3.55,3.63a26.43,26.43,0,0,1,.19,5.56c.06,3.61,1.22,7.15,1.16,10.76-.05,2.81-1.14,5.93-3.71,7.06a9.93,9.93,0,0,1-4,.54l-29.3-.19a4.64,4.64,0,0,1-2.06-.34c-1.32-.69-1.6-2.43-1.72-3.92q-.78-9.54-1.57-19.09Z", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.30000000000000004
} }), /* @__PURE__ */ React.createElement("path", { d: "M365.54,361.32l.38,4.59.26,3.28.69,8.54.39,4.89.54,6.53L368,392l.06.83.27,3.28.36,4.52.23,2.79.23,2.83.27,3.29.2,2.51.23,2.79L370,416h41.72l.1-1.22L412,412l.2-2.49.27-3.29.24-2.84.22-2.79.36-4.51.27-3.29.08-.83.22-2.8.52-6.53.4-4.9.69-8.53.27-3.28.37-4.59H365.54Zm2.31,2.13h.95l-.88.88h0Zm4,0h1l4.18,4.18-4.67,4.66-4.07-4.06-.08-1.12Zm-2.73,15.09,3.22-3.22L377,380l-4.67,4.67-3-3Zm43.24,3.12-3,3L404.69,380l4.66-4.65,3.23,3.22h0Zm-33.84-12.53,4.66,4.67-4.66,4.67-4.67-4.67Zm0-3-2.67-2.67h5.34Zm6.17,9.2,4.67,4.65-4.67,4.67L380,380Zm6.17,15.49-4.65-4.66,4.65-4.66,4.67,4.66Zm6.18-3.15,4.66,4.67L397,397l-4.67-4.67Zm-7.68,4.66L384.65,397,380,392.33l4.66-4.67Zm-3.15-18.52,4.65-4.67,4.67,4.67-4.67,4.67ZM397,375.32l4.66,4.65L397,384.64,392.34,380Zm1.51-1.52,4.67-4.67,4.66,4.67-4.66,4.67Zm9.33,12.35-4.66,4.66-4.67-4.66,4.67-4.66Zm-4.66,7.69,4.66,4.66-4.66,4.66-4.67-4.66Zm-1.52,10.84L397,409.34l-4.66-4.66L397,400Zm-10.84-1.52-4.65-4.66,4.65-4.66,4.67,4.66Zm-1.5,1.52-4.67,4.66L380,404.68l4.66-4.67Zm-6.18,6.17-3,3h-3.29l-3-3,4.67-4.67Zm-4.66-7.69-4.67-4.66,4.67-4.66,4.66,4.66Zm0-12.35-4.67-4.66,4.67-4.66,4.66,4.66Zm5.68-27.36h1l4.18,4.18-4.67,4.66L380,367.63Zm4,0h5.33l-2.67,2.67Zm8.34,0h1l4.17,4.18L397,372.29l-4.66-4.66Zm9.34,0-2.66,2.67-2.67-2.67Zm4,0,3.66,3.66-.09,1.12-4.06,4.06-4.66-4.66,4.17-4.18Zm4,0-.08.87h0l-.86-.87Zm-1,12.29-1.94-1.94,2.28-2.28Zm-.93,11.42-1-1,1.2-1.2h0l-.19,2.21Zm-.64,7.95L409.35,397l-4.66-4.66,4.66-4.67,2.3,2.31ZM411,398.6l-.1-.1.11-.1v.2Zm-.82,9.94-.79.79-4.66-4.65,4.66-4.67,1.38,1.38-.59,7.15Zm-2.3,5.33,1.51-1.51.45.44-.08,1.07Zm-3,0h-3.29l-3-3,4.67-4.67,4.66,4.67Zm-9.34,0,1.52-1.51,1.51,1.51Zm-6.3,0-3-3,4.65-4.67,4.67,4.67-3,3Zm-6.05,0,1.52-1.51,1.52,1.51Zm-11.28-1.07.45-.44,1.52,1.51h-1.88Zm-.92-11.41L372.3,400l4.67,4.67-4.67,4.65-.8-.79Zm-.25-3,.12.11-.1.1,0-.21ZM370,390l2.3-2.31,4.67,4.67L372.3,397l-1.89-1.88Zm-.41-5,1.21,1.2-1,1ZM368.5,371.5l2.3,2.3-2,2-.34-4.26Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  isolation: "isolate",
  opacity: 0.6000000000000001
} }, /* @__PURE__ */ React.createElement("path", { d: "M365.54,361.32l.38,4.59.26,3.28.69,8.54.39,4.89.54,6.53L368,392l.06.83.27,3.28.36,4.52.23,2.79.23,2.83.27,3.29.2,2.51.23,2.79L370,416h41.72l.1-1.22L412,412l.2-2.49.27-3.29.24-2.84.22-2.79.36-4.51.27-3.29.08-.83.22-2.8.52-6.53.4-4.9.69-8.53.27-3.28.37-4.59H365.54Zm2.31,2.13h.95l-.88.88h0Zm4,0h1l4.18,4.18-4.67,4.66-4.07-4.06-.08-1.12Zm-2.73,15.09,3.22-3.22L377,380l-4.67,4.67-3-3Zm43.24,3.12-3,3L404.69,380l4.66-4.65,3.23,3.22h0Zm-33.84-12.53,4.66,4.67-4.66,4.67-4.67-4.67Zm0-3-2.67-2.67h5.34Zm6.17,9.2,4.67,4.65-4.67,4.67L380,380Zm6.17,15.49-4.65-4.66,4.65-4.66,4.67,4.66Zm6.18-3.15,4.66,4.67L397,397l-4.67-4.67Zm-7.68,4.66L384.65,397,380,392.33l4.66-4.67Zm-3.15-18.52,4.65-4.67,4.67,4.67-4.67,4.67ZM397,375.32l4.66,4.65L397,384.64,392.34,380Zm1.51-1.52,4.67-4.67,4.66,4.67-4.66,4.67Zm9.33,12.35-4.66,4.66-4.67-4.66,4.67-4.66Zm-4.66,7.69,4.66,4.66-4.66,4.66-4.67-4.66Zm-1.52,10.84L397,409.34l-4.66-4.66L397,400Zm-10.84-1.52-4.65-4.66,4.65-4.66,4.67,4.66Zm-1.5,1.52-4.67,4.66L380,404.68l4.66-4.67Zm-6.18,6.17-3,3h-3.29l-3-3,4.67-4.67Zm-4.66-7.69-4.67-4.66,4.67-4.66,4.66,4.66Zm0-12.35-4.67-4.66,4.67-4.66,4.66,4.66Zm5.68-27.36h1l4.18,4.18-4.67,4.66L380,367.63Zm4,0h5.33l-2.67,2.67Zm8.34,0h1l4.17,4.18L397,372.29l-4.66-4.66Zm9.34,0-2.66,2.67-2.67-2.67Zm4,0,3.66,3.66-.09,1.12-4.06,4.06-4.66-4.66,4.17-4.18Zm4,0-.08.87h0l-.86-.87Zm-1,12.29-1.94-1.94,2.28-2.28Zm-.93,11.42-1-1,1.2-1.2h0l-.19,2.21Zm-.64,7.95L409.35,397l-4.66-4.66,4.66-4.67,2.3,2.31ZM411,398.6l-.1-.1.11-.1v.2Zm-.82,9.94-.79.79-4.66-4.65,4.66-4.67,1.38,1.38-.59,7.15Zm-2.3,5.33,1.51-1.51.45.44-.08,1.07Zm-3,0h-3.29l-3-3,4.67-4.67,4.66,4.67Zm-9.34,0,1.52-1.51,1.51,1.51Zm-6.3,0-3-3,4.65-4.67,4.67,4.67-3,3Zm-6.05,0,1.52-1.51,1.52,1.51Zm-11.28-1.07.45-.44,1.52,1.51h-1.88Zm-.92-11.41L372.3,400l4.67,4.67-4.67,4.65-.8-.79Zm-.25-3,.12.11-.1.1,0-.21ZM370,390l2.3-2.31,4.67,4.67L372.3,397l-1.89-1.88Zm-.41-5,1.21,1.2-1,1ZM368.5,371.5l2.3,2.3-2,2-.34-4.26Z", style: {
  fill: "#fff"
} })), /* @__PURE__ */ React.createElement("rect", { x: 362.63, y: 359.41, width: 56.39, height: 5.95, rx: 1.64, style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 366.7, y: 412.81, width: 48.26, height: 5.95, rx: 1.64, transform: "translate(781.65 831.57) rotate(180)", style: {
  fill: "#2994E5"
} })), /* @__PURE__ */ React.createElement("g", { id: "freepik--speech-bubble--inject-222" }, /* @__PURE__ */ React.createElement("path", { d: "M226.56,115.71H193.09a11.51,11.51,0,0,0-11.51,11.51v32.52a11.51,11.51,0,0,0,11.51,11.51h4.55l0,12.15,7.71-12.15h21.18a11.51,11.51,0,0,0,11.51-11.51V127.22A11.51,11.51,0,0,0,226.56,115.71Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M226.56,115.71H193.09a11.51,11.51,0,0,0-11.51,11.51v32.52a11.51,11.51,0,0,0,11.51,11.51h4.55l0,12.15,7.71-12.15h21.18a11.51,11.51,0,0,0,11.51-11.51V127.22A11.51,11.51,0,0,0,226.56,115.71Z", style: {
  fill: "#fff",
  opacity: 0.4
} }), /* @__PURE__ */ React.createElement("path", { d: "M215.36,138.16c0-2.45-2.09-4.1-5.45-4.1a8.22,8.22,0,0,0-7.1,3.35l-4.23-2.74c2.44-3.32,6.36-5.32,11.81-5.32,6.41,0,10.69,2.92,10.69,7.93,0,7.11-7.46,7.76-7.46,12.91h-5.54C208.08,143.74,215.36,142.52,215.36,138.16Zm-8,19.05a3.47,3.47,0,1,1,3.48,3.4A3.33,3.33,0,0,1,207.39,157.21Z", style: {
  fill: "#fff"
} })), /* @__PURE__ */ React.createElement("g", { id: "freepik--Character--inject-222" }, /* @__PURE__ */ React.createElement("path", { d: "M142.85,270.11s2.82,56.47,15,66.19h-13s-11.6-19.24-12.47-66.19Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M142.85,270.11s2.82,56.47,15,66.19h-13s-11.6-19.24-12.47-66.19Z", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("path", { d: "M133.08,278.07h12.31c3.61,0,6.22-3.24,5.82-7.24l-.52-5.34c-.4-4-3.64-7.24-7.25-7.24H131.13c-3.61,0-6.21,3.25-5.82,7.24l.53,5.34C126.23,274.83,129.47,278.07,133.08,278.07Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M131.17,282.47H152c6.1,0,10.51-5.49,9.84-12.25l-.89-9c-.66-6.77-6.15-12.25-12.25-12.25H127.87c-6.11,0-10.51,5.48-9.85,12.25l.89,9C119.58,277,125.07,282.47,131.17,282.47Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M131.17,282.47H152c6.1,0,10.51-5.49,9.84-12.25l-.89-9c-.66-6.77-6.15-12.25-12.25-12.25H127.87c-6.11,0-10.51,5.48-9.85,12.25l.89,9C119.58,277,125.07,282.47,131.17,282.47Z", style: {
  fill: "#fff",
  opacity: 0.5
} }), /* @__PURE__ */ React.createElement("path", { d: "M141.39,330.53h58.47a4.49,4.49,0,0,1,4.49,4.49v4.87a0,0,0,0,1,0,0H136.9a0,0,0,0,1,0,0V335A4.49,4.49,0,0,1,141.39,330.53Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M141.39,330.53h58.47a4.49,4.49,0,0,1,4.49,4.49v4.87a0,0,0,0,1,0,0H136.9a0,0,0,0,1,0,0V335A4.49,4.49,0,0,1,141.39,330.53Z", style: {
  fill: "#fff",
  opacity: 0.5
} }), /* @__PURE__ */ React.createElement("path", { d: "M139.25,400.55l26-2.27V364.13h9.14v34.14l26.1,2.28a4.49,4.49,0,0,1,4,4.47v3.46H135.19V405A4.5,4.5,0,0,1,139.25,400.55Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M196.13,412.78a6,6,0,1,0,6-6A6,6,0,0,0,196.13,412.78Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M143.61,412.78a6,6,0,1,1-6-6A6,6,0,0,1,143.61,412.78Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M167.39,337.67h4.86a3.71,3.71,0,0,1,3.71,3.71V371.1a0,0,0,0,1,0,0H163.68a0,0,0,0,1,0,0V341.38A3.71,3.71,0,0,1,167.39,337.67Z", transform: "translate(339.65 708.76) rotate(180)", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M167.39,337.67h4.86a3.71,3.71,0,0,1,3.71,3.71V371.1a0,0,0,0,1,0,0H163.68a0,0,0,0,1,0,0V341.38A3.71,3.71,0,0,1,167.39,337.67Z", transform: "translate(339.65 708.76) rotate(180)", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("rect", { x: 134.33, y: 336.3, width: 72.59, height: 4.68, rx: 1.81, style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M196.13,412.78a6,6,0,1,0,6-6A6,6,0,0,0,196.13,412.78Z", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("path", { d: "M143.61,412.78a6,6,0,1,1-6-6A6,6,0,0,1,143.61,412.78Z", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("path", { d: "M146,195.51s1.75-5.62,9.09-6.64,16.28,1.45,18.1,4.51.42,6.07,2.87,7.08,5.94,3.5,5.26,6-3.15,7.57-.43,8.56,4.43,5,3.24,7-1.62,4.48-.26,5.18,3,2.07,1.87,4.15-2.21,2.37-.51,4.14-1.24,5.75-1.24,5.75-39.26,4.67-44,.56-1.79-7.74-.77-8.82-1.51-.66-1.86-4.74,2.12-4.68,2.63-7.07-2-4.68-.34-7,2.19-3.62,1-6.06S139.26,195.68,146,195.51Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M213.37,235.53l8.7-1.16-5.32-8.16s-7.79,3.24-6.84,7.17Z", style: {
  fill: "#e4897b"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "228.84 228.9 221.88 223.44 216.75 226.21 222.07 234.37 228.84 228.9", style: {
  fill: "#e4897b"
} }), /* @__PURE__ */ React.createElement("path", { d: "M215.47,235.6s-10,35.23-17.85,33.1c-4.34-1.18-8.51-5.36-11.83-9.7a77.54,77.54,0,0,1-7.29-11.76v-.13l-1-16.72c7.77,1.91,19.16,22.84,19.16,22.84l12.58-20.65Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M176,254.25a116.71,116.71,0,0,0-23.9-16.66q1.19-.15,2.4-.24a115.5,115.5,0,0,1,16.5-.56c1.37.08,2.77.19,4.15.31h0C178.6,244.93,176,254.25,176,254.25Z", style: {
  fill: "#fff",
  opacity: 0.7000000000000001
} }), /* @__PURE__ */ React.createElement("path", { d: "M152.14,289.34c-.75-8.45-3.78-23.95-9-40.23-1.7-5.29,1.18-11.26,1.18-11.26s7.09-.19,7.81-.26,1.59-.18,2.4-.24a115.5,115.5,0,0,1,16.5-.56c1.37.08,2.77.19,4.15.31.44,0,5.43.09,5.86.13,0,0,6.91,7.9,7.33,14.3a114.34,114.34,0,0,1,.57,14.81c0,5.81-.46,13.47-.84,21.63Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M152.14,289.34c-.75-8.45-3.78-23.95-9-40.23-1.7-5.29,1.18-11.26,1.18-11.26s7.09-.19,7.81-.26,1.59-.18,2.4-.24a115.5,115.5,0,0,1,16.5-.56c1.37.08,2.77.19,4.15.31.44,0,5.43.09,5.86.13,0,0,6.91,7.9,7.33,14.3a114.34,114.34,0,0,1,.57,14.81c0,5.81-.46,13.47-.84,21.63Z", style: {
  fill: "#fff",
  opacity: 0.8
} }), /* @__PURE__ */ React.createElement("path", { d: "M152.14,289.34l36-2.43s1.08,10.82.83,10.66-38.55,1.35-38.55,1.35Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "152.47 289.94 188.11 287.97 188.11 291.35 152.14 293.26 152.47 289.94" }), /* @__PURE__ */ React.createElement("path", { d: "M172.53,292.73a1,1,0,0,1-1-.9l-.16-2a1,1,0,0,1,.9-1.05l3.9-.3a1,1,0,0,1,.74.26,1,1,0,0,1,.31.71v2.09a1,1,0,0,1-.92,1l-3.75.2Zm3.75-3.65h0l-3.91.3a.39.39,0,0,0-.36.43l.16,2a.37.37,0,0,0,.41.36l3.75-.2a.39.39,0,0,0,.37-.39v-2.09a.4.4,0,0,0-.12-.29A.39.39,0,0,0,176.28,289.08Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M190.34,295.34c-9.57-.95-22-1.72-38.2-2.08,0,0-12.07,28.43,2.24,36.92,4.33,2.57,11.47,3.59,19.33,3.75a16.25,16.25,0,0,0,16.63-16.23Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M155.06,314.09l-12.44-2.26,7.24-26.78S148,278.4,146,270.78c-.51-1.92-1-3.89-1.54-5.84s-.94-3.63-1.36-5.32a91.13,91.13,0,0,1-2.3-10.6c-.23-4.27-.94-15.82,3.16-17.63,2.43-1.08,7.41,1.47,10.56,2.06a67,67,0,0,1,7.57,29.32C162.59,280.08,155.06,314.09,155.06,314.09Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M166.88,233.85s11.62,1.45,16.57,26.2c2,10,6.76,52.83,6.76,52.83l2.7-1.35L189.16,286s1.35-29.58.3-35.65-6.37-19.23-11.91-20C175.3,230.09,166.88,233.85,166.88,233.85Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M167.42,233.5,168,233a26.83,26.83,0,0,1,17.14,9.58l-2.7,2.64L187,249l-2.2,20.26s-7-23.9-10.07-29.64C174.75,239.57,169.66,234.6,167.42,233.5Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M167.42,233.5l.54-.44a26.67,26.67,0,0,1,17.14,9.54l-2.7,2.64L187,249l-2.2,20.26s-7-23.9-10.07-29.64C174.75,239.57,169.66,234.6,167.42,233.5Z", style: {
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("path", { d: "M154.78,234.53s9,11.46,7.05,36.67l-9.67-18.13,3.08-4.95-7.2-6.75s4.65-9,7.32-8.31Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M154.78,234.53s9,11.46,7.05,36.67l-9.67-18.13,3.08-4.95-7.2-6.75s4.65-9,7.32-8.31Z", style: {
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("path", { d: "M155.54,232.51s2.72,1,11.45.35c0,0,3.3,3.06,4.34,6.27s-2.28,6.32-7.56,3.51S155.54,232.51,155.54,232.51Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M155.54,232.51s2.72,1,11.45.35c0,0,3.3,3.06,4.34,6.27s-2.28,6.32-7.56,3.51S155.54,232.51,155.54,232.51Z", style: {
  fill: "#fff",
  opacity: 0.5
} }), /* @__PURE__ */ React.createElement("path", { d: "M173.17,239.12l-2,11.95-15.72-13.75c3.33-3.67,1.23-13.33-.38-18.6l4.45,3.34,6.62,4.95a12.09,12.09,0,0,0,.32,4.07c.5,2.11,1.4,4.09,2.39,4.51Z", style: {
  fill: "#e4897b"
} }), /* @__PURE__ */ React.createElement("path", { d: "M166.46,231.08c-2.64-.59-6.1-3.32-6.66-6a12.39,12.39,0,0,1-.28-3l6.62,4.95A12.09,12.09,0,0,0,166.46,231.08Z", style: {
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("path", { d: "M155.54,232.51a137.27,137.27,0,0,1,15.31,17.94s1.56-12-3.86-17.59l1.25-.15a50.58,50.58,0,0,1,11.57,10.46l-5.21-1.65L171,251.16,160.3,242.3l-7.21,1.25S152.76,234.65,155.54,232.51Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M155.54,232.51a137.27,137.27,0,0,1,15.31,17.94s1.56-12-3.86-17.59l1.25-.15a50.58,50.58,0,0,1,11.57,10.46l-5.21-1.65L171,251.16,160.3,242.3l-7.21,1.25S152.76,234.65,155.54,232.51Z", style: {
  fill: "#fff",
  opacity: 0.7000000000000001
} }), /* @__PURE__ */ React.createElement("path", { d: "M150.17,208.22c1.9,8.52,2.53,12.16,7.56,16.1,7.57,5.94,17.57,2.32,18.24-6.45.6-7.9-2.61-20.5-11.55-23A11.17,11.17,0,0,0,150.17,208.22Z", style: {
  fill: "#e4897b"
} }), /* @__PURE__ */ React.createElement("path", { d: "M161.17,209.28c.13.72.61,1.26,1.07,1.21s.74-.67.61-1.39-.61-1.27-1.08-1.22S161,208.56,161.17,209.28Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M170.16,208c.13.72.61,1.26,1.07,1.21s.74-.67.61-1.4-.61-1.26-1.07-1.21S170,207.3,170.16,208Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M170.57,206.69l1.61-.68S171.55,207.45,170.57,206.69Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M167.69,209.91a21.44,21.44,0,0,0,3.35,4.33,2.93,2.93,0,0,1-2.44.76Z", style: {
  fill: "#de5753"
} }), /* @__PURE__ */ React.createElement("path", { d: "M161.79,202.42a.43.43,0,0,0-.26.2,3.12,3.12,0,0,1-2.42,1.72.39.39,0,0,0-.37.44.45.45,0,0,0,.47.4,3.91,3.91,0,0,0,3.1-2.14.44.44,0,0,0-.2-.57A.46.46,0,0,0,161.79,202.42Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M169.82,200.46a.39.39,0,0,0-.29.09.43.43,0,0,0,0,.6,4.48,4.48,0,0,0,3.61,1.65.39.39,0,0,0,.35-.46A.45.45,0,0,0,173,202a3.6,3.6,0,0,1-2.85-1.34A.47.47,0,0,0,169.82,200.46Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M161.58,207.94l1.61-.68S162.56,208.71,161.58,207.94Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M154.54,213.16s4.19-6.1,1-15.13c0,0,9.47-4.6,16.64,2.41,0,0-6.67-11.7-20.61-6.1s-3.43,19-1.7,20.11S154.54,213.16,154.54,213.16Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M148.47,215.59a7,7,0,0,0,4.61,2.71c2.36.28,3-2.1,2-4.28-1-2-3.36-4.49-5.52-3.75S147,213.72,148.47,215.59Z", style: {
  fill: "#e4897b"
} }), /* @__PURE__ */ React.createElement("path", { d: "M168.61,219a.29.29,0,0,1-.21-.09,6.23,6.23,0,0,0-5.16-1.59.29.29,0,0,1-.34-.23.3.3,0,0,1,.24-.34,6.69,6.69,0,0,1,5.68,1.76.29.29,0,0,1,0,.41A.27.27,0,0,1,168.61,219Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M144.42,264.94c-.48-1.83-.94-3.63-1.36-5.32,2.3-2.77,3.52-14.64,3.52-14.64S148.18,259.45,144.42,264.94Z", style: {
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("path", { d: "M115.06,236.53l-8.7-1.16,5.32-8.16s7.79,3.45,6.85,7.39Z", style: {
  fill: "#e4897b"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "99.59 229.9 106.55 224.44 111.68 227.21 106.36 235.37 99.59 229.9", style: {
  fill: "#e4897b"
} }), /* @__PURE__ */ React.createElement("path", { d: "M113,236.6s10,35.23,17.85,33.1c4.35-1.18,8.51-5.36,11.83-9.7a25.62,25.62,0,0,0,5-11.76l-3.69-16.85c-4.68,1-12.19,22.84-12.19,22.84l-12.59-20.65Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M159.07,318.76v13.36c11.9,3.43,36.61,2.12,51.3.93a12.74,12.74,0,0,0,11.7-12.71v-11a8.71,8.71,0,0,0-5.64-8.16c-7.12-2.68-20.08-6.67-33.64-6.46A24,24,0,0,0,159.07,318.76Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M220.82,357.39c-4.33,11.7-9.33,26.35-14.67,44.84-.1.34-.2.68-.29,1s-.21.71-.31,1.07L200,403l-2.08-.51s.09-.82.26-2.27c0-.3.07-.62.1-1h0c1.49-12.9,7.21-60.24,12.89-70.07,0,0-6.43,1.19-11.31.37-3.51-.59-7.77-24.91-7.77-24.91A74.94,74.94,0,0,1,210.27,302h.13s20.58,3.23,24,8.29C238.66,316.52,232.06,327,220.82,357.39Z", style: {
  fill: "#e4897b"
} }), /* @__PURE__ */ React.createElement("path", { d: "M213.68,418.75l-12.92-5.7-4.08-4.56-1.27,1.72-1.54-.67c-.72-5.74,2.06-8.42,3.53-9.64.1-.09.25-.2.43-.33a.7.7,0,0,1,.17-.13l.07-.05.2-.15,0,0h0l.12-.07,7.09,3.13s.12.37.34,1c.71,2,2.46,6.55,4.4,9,.08.1.16.21.24.3a6.11,6.11,0,0,0,.72.72C216.66,417.6,213.68,418.75,213.68,418.75Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M210.5,412.52c-2,.79-4.87-1.21-7-3.82A39.64,39.64,0,0,1,200,403a12.76,12.76,0,0,0-1.82-2.78c-.3-.27-.58-.38-.82-.27a0,0,0,0,0,0,0c.1-.09.25-.2.43-.33a.84.84,0,0,1,.17-.13l.07-.05.2-.15,0,0h0l.12-.07c.74-.56,1.57-1.13,1.57-1.13l5.7,2.52s.16.67.45,1.7c.74,2.64,2.31,7.65,4.11,10C210.34,412.32,210.42,412.43,210.5,412.52Z", style: {
  fill: "#e4897b"
} }), /* @__PURE__ */ React.createElement("path", { d: "M220.82,357.39c-4.33,11.7-9.33,26.35-14.67,44.84.74,2.64,2.31,7.65,4.11,10,.08.1.16.21.24.3a6.11,6.11,0,0,0,.72.72c5.44,4.36,2.46,5.51,2.46,5.51l-12.92-5.7-4.08-4.56-1.27,1.72-1.54-.67c-.72-5.74,2.06-8.42,3.53-9.64.1-.09.25-.2.43-.33a.7.7,0,0,1,.17-.13l.07-.05.2-.15,0,0h0c1.49-12.9,7.21-60.24,12.89-70.07,0,0-6.43,1.19-11.31.37-3.51-.59-7.77-24.91-7.77-24.91A74.94,74.94,0,0,1,210.27,302h.13s-2.17.21-5.12,3.91c0,0,12,2.24,15.29,8.6C221.88,317.11,221.61,335.91,220.82,357.39Z", style: {
  isolation: "isolate",
  opacity: 0.1
} }), /* @__PURE__ */ React.createElement("path", { d: "M192.11,304.6s20.59,3.23,24,8.28c5.93,8.7-4.75,25.8-24.23,94l-7.6-1.86s2-61.9,8.6-73.31c0,0-6.43,1.19-11.3.37C175.34,331,171.39,310.14,192.11,304.6Z", style: {
  fill: "#e4897b"
} }), /* @__PURE__ */ React.createElement("path", { d: "M183.07,418.32h1.69l.46-2.08,5.58,2.52h14.12s2.25-2.26-4.48-4.06a4.75,4.75,0,0,1-.95-.37c-3.59-1.68-8.69-7.36-8.69-7.36h-7.75a10.5,10.5,0,0,0-.64,1.12C181.56,409.79,180.1,413.37,183.07,418.32Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M182.41,408.09v0c1-1.14,5.68,3.87,9.09,5.58,3.05,1.52,6.47,2.19,8,.64-3.59-1.68-9.23-9-9.23-9H184S182.78,407.36,182.41,408.09Z", style: {
  fill: "#e4897b"
} })), /* @__PURE__ */ React.createElement("g", { id: "freepik--Desk--inject-222" }, /* @__PURE__ */ React.createElement("g", { style: {
  isolation: "isolate",
  opacity: 0.30000000000000004
} }, /* @__PURE__ */ React.createElement("path", { d: "M316.91,281.48v134a3.22,3.22,0,0,0,3.23,3.22h62.51a1.22,1.22,0,0,0,1.22-1.22v-4a1.23,1.23,0,0,0-1.22-1.23H323.36V284.7h58.28a2.24,2.24,0,0,0,2.23-2.25v-4.2H320.14A3.22,3.22,0,0,0,316.91,281.48Z", style: {
  fill: "#fff"
} })), /* @__PURE__ */ React.createElement("path", { d: "M316.91,281.48v34.18q3.22.82,6.45,1.58V284.7h58.28a2.24,2.24,0,0,0,2.23-2.25v-4.2H320.14A3.22,3.22,0,0,0,316.91,281.48Z", style: {
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("polygon", { points: "309.62 269.05 308.82 266.06 276.25 266.06 261.25 267.55 260.68 269.05 309.62 269.05", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "309.62 269.05 308.82 266.06 276.25 266.06 261.25 267.55 260.68 269.05 309.62 269.05", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.5
} }), /* @__PURE__ */ React.createElement("polygon", { points: "276.25 266.06 276.25 269.05 309.62 269.05 308.82 266.06 276.25 266.06", style: {
  isolation: "isolate",
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("path", { d: "M287.27,246.77a3.3,3.3,0,0,0,3.33,3.68h64.13a4.13,4.13,0,0,0,4-3.68l3.81-41.06a3.37,3.37,0,0,0-.09-1.2,3.27,3.27,0,0,0-3.24-2.49H295.1a4.12,4.12,0,0,0-4,3.69Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("path", { d: "M287.27,246.77a3.3,3.3,0,0,0,3.33,3.68h64.13a4.13,4.13,0,0,0,4-3.68l3.81-41.06a3.37,3.37,0,0,0-.09-1.2,3.27,3.27,0,0,0-3.24-2.49H295.1a4.12,4.12,0,0,0-4,3.69Z", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.5
} }), /* @__PURE__ */ React.createElement("path", { d: "M290.82,250.45h63.91a4.13,4.13,0,0,0,4-3.68l3.81-41.06a3.37,3.37,0,0,0-.09-1.2h-63.9a4.14,4.14,0,0,0-4,3.69l-3.81,41.05A3.63,3.63,0,0,0,290.82,250.45Z", style: {
  isolation: "isolate",
  opacity: 0.1
} }), /* @__PURE__ */ React.createElement("polygon", { points: "334.88 225.27 321.62 225.27 325.15 263.26 304.17 266.41 304.17 269.05 348.36 269.05 348.36 266.41 339.31 263.26 334.88 225.27", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("polygon", { points: "334.88 225.27 321.62 225.27 325.15 263.26 304.17 266.41 304.17 269.05 348.36 269.05 348.36 266.41 339.31 263.26 334.88 225.27", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.5
} }), /* @__PURE__ */ React.createElement("polygon", { points: "321.62 225.27 331.45 268.45 339.31 268.21 334.88 225.27 321.62 225.27", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("path", { d: "M228.78,418.76h62.51a1.23,1.23,0,0,0,1.23-1.23v-4a1.23,1.23,0,0,0-1.23-1.23H232V284.69h58.27a2.23,2.23,0,0,0,2.24-2.23v-4.21H228.78a3.22,3.22,0,0,0-3.22,3.22V415.53A3.23,3.23,0,0,0,228.78,418.76Z", style: {
  fill: "#263238"
} }), /* @__PURE__ */ React.createElement("g", { style: {
  isolation: "isolate",
  opacity: 0.4
} }, /* @__PURE__ */ React.createElement("path", { d: "M228.78,418.76h62.51a1.23,1.23,0,0,0,1.23-1.23v-4a1.23,1.23,0,0,0-1.23-1.23H232V284.69h58.27a2.23,2.23,0,0,0,2.24-2.23v-4.21H228.78a3.22,3.22,0,0,0-3.22,3.22V415.53A3.23,3.23,0,0,0,228.78,418.76Z", style: {
  fill: "#fff"
} })), /* @__PURE__ */ React.createElement("path", { d: "M387.65,272.16v5.21a2,2,0,0,1-1.95,1.95H220.63a1.94,1.94,0,0,1-1.94-1.95v-5.21Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("rect", { x: 218.69, y: 272.15, width: 168.96, height: 3.58, transform: "translate(606.34 547.89) rotate(180)", style: {
  isolation: "isolate",
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("path", { d: "M393.21,271.07a2,2,0,0,1-2,2h-176a2,2,0,0,1-1.43-.59,1.46,1.46,0,0,1-.26-.34,2,2,0,0,1-.33-1.09,2,2,0,0,1,2-2h176a2,2,0,0,1,2,2Z", style: {
  fill: "#2994E5"
} }), /* @__PURE__ */ React.createElement("path", { d: "M300.61,269.64a2,2,0,0,0-1.43-.59h-84a2,2,0,0,0-2,2,2,2,0,0,0,.33,1.09,1.46,1.46,0,0,0,.26.34,2,2,0,0,0,1.43.59h3.54v4.28a1.94,1.94,0,0,0,1.94,1.95H293.7a1.94,1.94,0,0,0,1.94-1.95v-4.28h3.54a2,2,0,0,0,1.71-.93,2,2,0,0,0-.28-2.52Z", style: {
  fill: "#fff",
  isolation: "isolate",
  opacity: 0.2
} }), /* @__PURE__ */ React.createElement("path", { d: "M316.91,281.48v134a3.22,3.22,0,0,0,3.23,3.22h62.51a1.22,1.22,0,0,0,1.22-1.22v-4a1.23,1.23,0,0,0-1.22-1.23H323.36V284.7h58.28a2.24,2.24,0,0,0,2.23-2.25v-4.2H320.14A3.22,3.22,0,0,0,316.91,281.48Z", style: {
  fill: "#263238"
} })));
export default SvgShrug;
