import { css } from '@emotion/react';
import { IonContent, IonPage } from '@ionic/react';
import * as Sentry from '@sentry/react';
import React from 'react';
import ErrorPerson from '../ErrorPerson';
import PageHeader from '../PageHeader';
import { Title } from '../Title';

interface SentryErrorBoundaryProps {
  children: React.ReactNode;
}

const ErrorFallbackCss = {
  outerContainer: [
    css({
      padding: '1rem',
    }),
  ],
};

const ErrorFallback: React.FC = () => (
  <IonPage>
    <PageHeader title="Oops!" />
    <IonContent>
      <div css={ErrorFallbackCss.outerContainer}>
        <ErrorPerson title="Something went wrong...">
          <Title level={2}>
            Our team has been alerted to this issue.
            <br />
            Please, try again later.
          </Title>
        </ErrorPerson>
      </div>
    </IonContent>
  </IonPage>
);

const SentryErrorBoundary: React.FC<SentryErrorBoundaryProps> = ({ children }) => {
  return <Sentry.ErrorBoundary fallback={<ErrorFallback />}>{children}</Sentry.ErrorBoundary>;
};

export default SentryErrorBoundary;
