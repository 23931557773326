import { useIonRouter } from '@ionic/react';
import React, { useEffect } from 'react';

export const RedirectHandler: React.FC<{ url: string }> = ({ url }) => {
  const router = useIonRouter();

  useEffect(() => {
    router.push(url, 'forward', 'replace');
  }, [url, router]);

  return null;
};
