import { AqiLevelDto, Brand, PollutantConfigDto } from '../../types';

export type AqiStandardId = Brand<string, 'AqiStandardId'>;

export enum AirQualityClassificationType {
  AQI = 'AQI',
  AQC = 'AQC',
}

export class AqiStandardV1Dto {
  /**
   * Name of the AQI standard
   * @example 'US EPA AQI'
   */
  name: string;
  aqiStandardId: AqiStandardId;
  aqiLevels: AqiLevelDto[];
  aqiClassType: AirQualityClassificationType;
  aboutLink: string;
}
export class AqiStandardV2Dto {
  /**
   * Name of the AQI standard
   * @example 'US EPA AQI'
   */
  name: string;
  aqiStandardId: AqiStandardId;

  aqiLevels: AqiLevelDto[];
  aqiClassType: AirQualityClassificationType;

  aboutLink: string;

  pollutantConfigs: PollutantConfigDto[];
}
